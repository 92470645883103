<template>
  <div class="career">
    <Banner/>
    <div class="firstPlate">
      <div class="solutionPublicTitle">
        <p>加入我们</p>
        <p>JOIN US</p>
      </div>
      <div class="hy_jrwm_body">
        <div>
          <div class="job_name">
            <p><span>项目经理</span></p>
          </div>
          <div class="job_details_hide">
            <p>
              <span>1、负责客户需求的整理分析，结合公司的技术产品，为客户提供解决方案；</span>
              <span>2、负责项目内实施团队的管理、协调以及职责界定；</span>
              <span><button><a href="https://www.zhipin.com/job_detail/0b869002c9dc8a770n1-2ti1GFY~.html?ka=search_list_jname_1_blank&lid=nlp-6FKadIqiIZX.search.1" target="_blank">了解更多</a></button></span>
            </p>
          </div>
        </div>
        <div>
          <div class="job_name">
            <p><span>C++开发(解码器开发)</span></p>
          </div>
          <div class="job_details_hide">
            <p>
              <span>1、基于C++语言研发和维护语音识别、语音合成、机器翻译等算法的解码器；</span>
              <span>2、与项目组其他成员协同工作，按时完成研究开发任务；</span>
              <span><button><a href="https://www.zhipin.com/job_detail/a99f98c4adb4a12133V60t6-FVE~.html?ka=comp_joblist_2" target="_blank">了解更多</a></button></span>
            </p>
          </div>
        </div>
        <div>
          <div class="job_name">
            <p><span>语音数据标注</span></p>
          </div>
          <div class="job_details_hide">
            <p>
              <span>1、负责印度英语语音标注，公司内部会进行标注工作的培训；</span>
              <span>2、可根据自己的时间，灵活在线办公；</span>
              <span><button><a href="https://www.zhipin.com/job_detail/e354c40015ad5805331_2N-8FFU~.html?ka=comp_joblist_4" target="_blank">了解更多</a></button></span>
            </p>
          </div>
        </div>
        <div>
          <div class="job_name">
            <p><span>更多职位</span></p>
          </div>
          <div class="job_details_hide">
            <p>
              <span>这里没有繁文缛节的招聘职位描述，如果这些职位让你有所憧憬，欢迎了解更多职位信息；</span>
              <span>或者发送简历到</span>
              <span>hr@huiyan-tech.com</span>
              <span><button><a href="https://www.zhipin.com/gongsi/3df3c961d2b7b5191Hx729W1Fw~~.html?ka=job-detail-company-logo_custompage" target="_blank">了解更多</a></button></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="hy_index_gray1">
      <div class="hy_index_hzzx hy_index_hzzx1">
        <p><span>点击下方按钮，期待你的加入！</span></p>
        <div><button><a href="mailto:hr@huiyan-tech.com" target="_blank">联系 HR</a></button></div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner"
export default {
  name: "Career",
  components: {
    Banner,
  },
}
</script>

<style lang="less" scoped>
.firstPlate {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  .hy_jrwm_body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto 60px;
    >div{
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      position: relative;
      width: 290px;
      height: 400px;
      background: #FCFDFF;
      overflow: hidden;
    }
    >div:nth-child(1) {
      background: url('~@/assets/imgs/join/job1.jpg') no-repeat center center; background-size: 100% 100%;
    }
    >div:nth-child(2) {
      background: url('~@/assets/imgs/join/job2.jpg') no-repeat center center; background-size: 100% 100%;
    }
    >div:nth-child(3) {
      background: url('~@/assets/imgs/join/job3.jpg') no-repeat center center; background-size: 100% 100%;
    }
    >div:nth-child(4) {
      background: url('~@/assets/imgs/join/job4.jpg') no-repeat center center; background-size: 100% 100%;
    }
    .job_name{
      position: absolute;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      width: 270px;
      height: 114px;
      padding: 0 10px;
      background: rgba(60,60,60,0.6);
      opacity: 1;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      transition: opacity 0.3s;
      p{
        width: 100%;
        span{
          display: block;
        }
      }
      p:first-child span{
        font-family: 'siyuan-normal', serif;
        font-size: 20px;
        color: #fff;
      }
      p:last-child span{
        font-family: 'siyuan-normal', serif;
        font-size: 14px;
        color: #fff;
      }
    }
    .job_details_hide{
      position: absolute;
      top: 100%;
      bottom: -100%;
      left: 0;
      right: 0;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      background: rgba(23,74,191,0.8);
      opacity: 0;
      -webkit-transition: top 1s;
      -moz-transition: top 1s;
      transition: top 1s;
      -webkit-transition: bottom 1s;
      -moz-transition: bottom 1s;
      transition: bottom 1s;
      p{
        width: 80%;
        span{
          display: block;
          font-family: 'siyuan-normal', serif;
          font-size: 14px;
          color: #fff;
          >button{
            width: 120px;
            height: 44px;
            background: #fff;
            border: none;
            border-radius: 6px;
            font-size: 16px;
            color: #0049F2;
            cursor: pointer;
            a{
              display: block;
              width: 100%;
              height: 100%;
              line-height: 44px;
              color: inherit;
            }
          }
        }
        span:last-child{
          margin-top: 50px;
        }
      }
    }
  }
  .hy_jrwm_body>div:hover .job_name{
    opacity: 0;
  }
  .hy_jrwm_body>div:hover .job_details_hide{
    opacity: 1;
    top: 0;
    bottom: 0;
  }
}
</style>